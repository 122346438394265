body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a:hover {
  cursor: pointer;
}

@font-face {
  font-family: "Duplet";
  src: local("Duplet"), url("./fonts/dupletRegular.woff") format("woff");
}

.font-face {
  font-family: "Duplet";
}

.font-face-gm {
  font-family: "Duplet";
  font-style: normal;
  font-weight: 400;
  margin-left: 6.6%;
  text-align: left;
}

.grid {
  min-height: 100vh;
}

.heading {
  font-size: 74px;
  line-height: 105%;
  letter-spacing: -0.02em;
  margin-top: -8%;
  display: flex;
  align-items: flex-end;
}

.info-text {
  font-size: 28px;
  line-height: 115%;
  margin-top: 6%;
}

.button {
  text-transform: none;
  margin-top: 8%;
  padding: 14px 4px 14px 16px;
  width: 412px;
  height: 58px;
  border-radius: 8px;
  text-align: center;
}

.button-content {
  width: 336px;
  height: 26px;
  font-size: 19px;
  line-height: 120%;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.link {
  text-decoration: none;
  color: #9b28c4;
}

.subtext {
  font-size: 22px;
  line-height: 125%;
  margin-top: 2%;
  margin-left: 1%;
}

.top-margin {
  margin-top: 6%;
}

.bottom-margin {
  margin-bottom: 18%;
}

.li-bottom-margin {
  margin-bottom: 1%;
}

.title {
  font-size: 28px;
  line-height: 115%;
  margin-top: -6%;
}

.ul-content {
  font-size: 22px;
  line-height: 125%;
}

.support-text {
  font-size: 22px;
  line-height: 125%;
  margin-top: 8%;
}

@media (max-width: 490px) {
  .heading {
    font-size: 45px;
    margin-top: -5%;
  }

  .info-text {
    font-size: 17px;
    margin-top: 5%;
  }

  .button {
    width: 252px;
    height: 38px;
  }

  .button-content {
    width: 336px;
    height: 16px;
    font-size: 13px;
  }

  .subtext {
    font-size: 13px;
    margin-top: 1%;
  }

  .title {
    font-size: 17px;
    margin-top: -8.5%;
  }

  .ul-content {
    font-size: 12px;
  }

  .support-text {
    font-size: 13px;
  }
}

@media (min-width: 530px) and (max-width: 1000px) {
  .title {
    margin-top: -9%;
  }
}
